<template>
	<!-- begin login -->
	<div class="login login-with-news-feed">
		<!-- begin news-feed -->
		<div class="news-feed bg-red-transparent-2 image-latar">
			<div class="text-center pb-3" style="margin-top: 160px;">
				<img :src="logoSumut" width="200" alt="logoSumut">
			</div>
			<div>
				<h3 class="d-flex justify-content-center text-white shadow-text">PEMERINTAH PROVINSI SUMATERA UTARA</h3>
				<h3 class="d-flex justify-content-center text-white shadow-text">BADAN PENGELOLAAN PAJAK DAN RETRIBUSI DAERAH</h3>
				<p class="font-weight-bold d-flex justify-content-center text-white shadow-text" style="font-size: 15px">Jl. Sisingamangaraja Km 5,5 Medan</p>
			</div>
		</div>
		<!-- end news-feed -->
		<!-- begin right-content -->
		<div class="right-content">
			<!-- begin login-header -->
			<div class="login-header">
				<div class="brand">
					<!-- <span>
						<img :src="logoSumut" width="60" alt="logoSumut">
					</span> --> 
                    <div class="text-left">
                        <div>
                            PAJAK DAERAH
                        </div>
                        <div style="font-size: 20px">
                            PROVINSI SUMATERA UTARA
                        </div>
                    </div>
					<small>Silahkan masukkan alamat email dan kata sandi untuk masuk ke Aplikasi Pajak Daerah Provinsi Sumatera Utara</small>
				</div>
				<div class="icon">
					<i class="fa fa-sign-in"></i>
				</div>
			</div>
			<!-- end login-header -->
			<!-- begin login-content -->
            <b-alert variant="danger" show v-if="message" dismissible>
                {{ message }}
            </b-alert>
            <alert-error :form="form" class="border-danger mb-2" v-else></alert-error>
			<div class="login-content">
				<form v-on:submit.prevent="checkForm" method="POST" class="margin-bottom-0">
                    <!-- <input type="hidden" name="_token" :value="csrf"> -->
					<div class="form-group m-b-15">
						<input type="text" v-model="form.email" class="form-control form-control-lg" placeholder="Alamat Email" />
                        <em v-if="form.errors.has('email')" class="form-text text-danger">{{errors.email[0]}}</em>
					</div>
					<div class="form-group m-b-15">
						<input type="password" v-model="form.password" class="form-control form-control-lg" placeholder="Kata Sandi" />
                        <em v-if="form.errors.has('password')" class="form-text text-danger">{{errors.password[0]}}</em>
					</div>
					<div class="checkbox checkbox-css m-b-30">
						<input type="checkbox" id="remember_me_checkbox" value="" />
						<label for="remember_me_checkbox">
						Ingat Saya
						</label>
                        <router-link to="/auth/lupa-password" class="float-right font-weight-bold">Lupa password ?</router-link>
					</div>
					<div class="login-buttons">
						<button type="submit" class="btn btn-danger btn-block btn-lg">Masuk</button>
                        <p class="mt-2">
                            Belum punya akun ? 
                            <span>
                                <router-link to="/auth/daftar" class="font-weight-bold h5">
                                    Daftar
                                </router-link>
                            </span>
                        </p>
					</div>
					<hr />
					<p class="text-center text-grey-darker">
						&copy; Pemerintah Provinsi Sumatera Utara | All Right Reserved 2020
					</p>
				</form>
			</div>
			<!-- end login-content -->
		</div>
		<!-- end right-container -->
	</div>
	<!-- end login -->
</template>

<script>
/* eslint-disable */
    // eslint-disable-next-line
    // eslint-disable vue/no-unused-vars
    // eslint-disable-line no-unused-vars

import PageOptions from '@/config/PageOptions.vue'
import Logo from '@/assets/images/logo-sumut.png'
import danautoba from '@/assets/images/danautoba.jpg'
import axios from 'axios'

export default {
	data () {
		return {
			logoSumut: Logo,
            danauToba: danautoba,
			form: new Form({
				email: '',
				password: ''
			}),
            errors: [],
            message: ''
            // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')

		}
	},
	created() {
		PageOptions.pageEmpty = true;
        document.body.className = 'bg-white';
	},
	beforeRouteLeave (to, from, next) {
		PageOptions.pageEmpty = false;
		document.body.className = '';
		next();
	},
	methods: {
		checkForm: function(e) {
			e.preventDefault()
                // if (this.form.password.length > 0) {
                    // this.$Progress.start();
                    //this.btnLogin.Saving = true;
                    this.form
                        .post("/api/login")
                        .then(
                            response => {
                                // console.log(response.data.data.token);
                                // let is_admin = 1;
                                // let is_admin = response.data.user.is_admin;
                                localStorage.setItem('EP_token', response.data.data.token)
                                localStorage.setItem('user', JSON.stringify(response.data.data.user))
                                localStorage.setItem('roles', JSON.stringify(response.data.data.role))
                                localStorage.setItem('permissions', JSON.stringify(response.data.data.permission))
                                
                                // set roles vue gates 
                                this.$gates.setRoles(JSON.parse(localStorage.getItem('roles')))
                                

                                /**
                                 * Jika terjadi upgrade Role dan Permission, 
                                 * auth berubah otomatis setelah melakukan Refresh all
                                 * jika setRoles dan setPermission diletakkan di App.vue
                                 */
                                /**
                                 * Jika terjadi upgrade Role dan Permission, 
                                 * auth berubah otomatis setelah Login ulang
                                 * jika setRoles dan setPermission diletakkan di Login.vue
                                 */
                                // const roles = JSON.parse(localStorage.getItem('roles'));
                                // this.$laravel.setRoles(roles);
                                // const permissions = JSON.parse(localStorage.getItem('permissions'));
                                // this.$laravel.setPermissions(permissions);
                                /** 
                                 * Jika terjadi upgrade Role dan Permission, 
                                 * Kemudian auth berubah otomatis, 
                                 * jika setRoles dan setPermission diletakkan di setiap menu
                                 * atau membuat socket khusus setRole dan setPermission
                                 * setRoles dan setPermission memanggil langsung ke Database
                                 */
                                // const permissions = axios.get('/api/permissions')
                                // this.$laravel.setPermissions(permissions);
                                // const roles = axios.get('/api/roles')
                                // this.$laravel.setRoles(roles);
                                // ---
                                // this.$Progress.finish();
                                /* this.btnLogin.Saving = false;
                                // this.$router.push('/ourzone') */
                                if (localStorage.getItem('EP_token') != null) {
                                    this.$emit('loggedIn')
                                    if (this.$route.params.nextUrl != null) {
                                        this.$router.push(this.$route.params.nextUrl)
                                    } else {
                                        this.$router.push('/dashboard')
                                    }
                                }
                            },
                            error => {
                                //this.$Progress.fail();
                                //this.btnLogin.Saving = false;
                                if (error.response.status == 403) {
                                    this.form.reset();
                                    this.form.clear();
                                    this.$swal({
                                        title: "Warning",
                                        text: error.response.data.message,
                                        icon: "warning"
                                    }).then((result) => {
                                    })
                                }else if(error.response.status == 422) {
                                    this.errors = error.response.data.errors;
                                    console.log(error.response)
                                }else if(error.response.status == 404) {
                                    console.log('ooooooooooo')
                                }else if (error.response.status == 401){
                                    console.error(error);
                                    this.message = error.response.data.status.message
                                }
                            }
                        ).catch(e => {
                            // console.error(e);
                            // this.errors = e.response.data.errors;
                            //this.$Progress.fail();
                            //this.btnLogin.Saving = false;
                            this.$swal({
                                icon: 'error',
                                title: 'Terjadi Masalah',
                                text: e,
                                type: 'error',
                                confirmButtonClass: 'btn btn-info',
                                showConfirmButton: false,
                                showCloseButton: true,
                                timer: 1500
                            })
                        });
                // }
		}
	}
}
</script>

<style scoped>
.shadow-text {
    text-shadow: 2px 2px 3px black;
}
.image-latar {
    background-image: url("https://images.unsplash.com/photo-1440558929809-1412944a6225?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1774&q=80"); 
    background-position: center;
}
</style>